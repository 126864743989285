// Fix para font na edicao
body:not(.frontend){
    font-size: 14px;

    .detalhe-noticia,.share{display: none}
}



@media (min-width: 768px){

    /* fix para o padding das paginas com menu */
    #left-column{
        padding-right: 0;
    }
    #center-column.onecolumn{
        padding-left: 0;
    }

    .newsImageContainer{
        float: right;
        margin-left: 10px;
        max-width: 400px;
    }

    .share{
        display: block;
        height: 40px;

    }
}

@media (max-width: 767px){

    #canvas-container{
        height: 500px !important;
    }

    #footer .links-uteis{
      column-count: 1 !important;
    }

    .share{
        position: fixed;
        bottom: 0;
        left: 0;
        top: auto;
        z-index: 50;
        width: 100%;
        text-align: center;
    }

    .share a{
        width: 25% !important;
        float: left;
    }
}

// somente celulares na vertical
@media (max-width: 576px){
    .lista-detalhe .item{
        display: block !important;

        .imagem, .detalhe{
            width: 100% !important;
        }

        .detalhe{margin-left: 0 !important;}
    }
}

// FIX select2 colors
.select2-highlighted a{
    color: #007bb3 !important;
}

body.portaltype-ufal-noticia{
    header h1{
        font-size: 3rem !important;
        text-align: center;
    }

    p.lead, #section-byline{
        text-align: center;
    }
}

img.image-richtext.image-left{float: left}
img.image-richtext.image-right{float: right}

body.frontend{
    font-family: "ProximaNova";
    background-color: #e9e9e9;

    // Fix to remove xxl size
    @media (min-width: 1400px){
        .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
            max-width: 1140px;
        }
    }

    .entries::before, #content article.entry::after, #relatedItemBox ul li::after, #searchresults ul li::after {
        content: " ";
        display: block;
        clear: both;
        height: 2px;
        visibility: hidden;
    }

    b,strong{
        font-family: "ProximaNovaBold";
    }
    a{
         text-decoration: none;
         &:hover{
             text-decoration: underline;
         }
    }


    .hiddenStructure{display: none}

    .image-inline.zoomable, img-fluid{
        max-width: 100%;
        height: auto;
    }

    .img-full{width: 100%;}

    #edit-zone{
        z-index: 200;
    }

    #site {
        background-color: #fff;
        background-clip: content-box;
        margin-top: 9px;
    }

    #left-column{
        background-color: var(--ufal-main-color);
        background-clip: content-box;
    }

    #content-core{
        font-size: 16px;

        a:not(.btn){
            color:#151515;
        }

        p{
            line-height: 1.5;
            margin-bottom: 10px;
            font-size: 17px;
        }

        li{
            margin-bottom: 5px;
        }

        .description, article .col p{
            padding-left: 10px;
            font-size: 13px;
            color: #777777;
            font-weight: 300;
        }

        .state-private {
            color: #c4183c;
        }
    }

    .section-main{
        text-align: center;
        padding: 2em 1em 1em 1em;
    }

    .section-actions{
        text-align: center;
        margin-bottom: 3em;
    }

    .info-redirect{
        background-color: #ddd;
        padding: 10px;
        border-radius: 5px;
    }

    #section-text{
        a{text-decoration: underline;}
    }

    #section-category{
        margin-top: 15px;
    }

    #section-related{
        margin-top:25px;
        display: flex;
        flex-wrap: wrap;

        .section-heading{
            font-size: 19px;
            font-weight: bold;
            flex-basis: 100%;
            margin-bottom:10px;
        }

        small{display:none}

        .media{
            margin-right: 10px;
            width: 100%;
            display: flex;
            flex: 0 0 auto;
            position: relative;
            max-height: 110px;
            background-color: #aebdcb;
            border-radius: 5px!important;
            transition: all .2s ease;
            @media (min-width: 576px){width: 33.3333%;}
            @media (min-width: 992px){width: 25%;}

            &:after{
                background-image: url('../imgs/brasao-branco.png');
                background-repeat: no-repeat;
                content: "";
                opacity: .2;
                top: 0;
                left: -13px;
                bottom: 0;
                right: 0;
                position: absolute;
                transition: all .2s ease;
                z-index: 0;
            }

            &:hover{
                &:after{
                    left: 5px;
                }
            }

            .media-body{
                margin:auto;
                z-index: 1;
                flex-grow: 1;
            }

            a{
                display: block;
                
                padding: 42px 5px;
                text-align: center;
                margin-bottom: 0;
                height: 100%;
                text-decoration: none;
                overflow: hidden;
                color: #fff!important;
                
                
                &:after{display: none;}
                
            }
            & .media-body + img{display:none}
        }
    }

    #content{
        padding: 37px;
        position: relative;
    }

    #searchGadget_form{

        .searchButton, .searchSection{
            display: none;
        }
        .searchField{
            width: 100%;
            opacity: 0.5;
            padding: 10px 25px;
            border: none;
            border-radius: 20px;
            font-size: 14px;
            font-family: 'ProximaNova';
            line-height: 14px;
        }
        .searchField:focus{outline: none;}

        button[type="submit"]{
            float: right;
            background: none;
            border: 0;
            margin-top:-33px;
            z-index: 10;
            position: relative;
            line-height: 14px;
        }
    }

    #busca .fa-search{
        color:#fff;
    }

    /* breadcrumbs */

    #portal-breadcrumbs{
        background-color: #fff;
        border-bottom: 1px solid #e9e9e9;
        padding: 18px 12px;

        ol{
            padding: 0;
            margin-bottom: 0;
            list-style: none;
        }

        .breadcrumb-item{
            display: inline;
            font-size: 13px;
            color: #151515;
            font-weight: bold;
        }

        .breadcrumb-item+.breadcrumb-item::before{
            content: "\f105";
            font-family: "Font Awesome 6 Free";
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            font-size: 13px;
        }

        li:last-child:after{content: "";}

        a{color: #989898;}
    }

    article .col a{
        text-decoration: none !important;

        &:hover{
            text-decoration: underline !important;
        }
    }

    .event.summary {
        font-weight: 400;
        max-width: 300px;
        float: right;
        background: #fafafa;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
        padding: 12px 12px 12px 36px;
        margin-left: 5px;
    }

    header h1{
        font-size: 30px;
    }

    p.lead{
        margin: 15px 0;
        font-size: 18px;
        font-weight: 700;
        color: #696969;
    }

    #section-byline, article .col .mb-1+.mb-1 {
        color: #696969;
        font-weight: 400;
        font-size: 13px;
        margin-bottom: 10px;
    }

    //eventos
    .vevent {
        div.cal_date{
            margin: 0;
            border: 1px solid #333;
            border-radius: 0;
            width: 100px;
        }

        span.cal_month{
            padding: 3px 5px;
            color: #fff;
        }

        .timerange, .timezone{
            display: inline-block;
        }

    }

    pre {
        display: block;
        padding: 10px;
        margin: 0 0 10px;
        font-size: 13px;
        line-height: 1.42857143;
        word-break: break-all;
        word-wrap: break-word;
        color: #4d4d4d;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
    }


    // ufal.Noticia
    .detalhe-noticia{
        font-size: 14px;
        padding: 10px 0;
        line-height: 20px;
        border-bottom: 1px solid rgba(204,204,204,0.25);
        margin-bottom: 20px;
        // margin-top:28px;
        // margin-bottom: 30px;
    }

    .main-image{
        margin-bottom: 20px;
    }

    .portalMessage{
        margin: 20px 20px 0 20px;
    }

    .pagination{

        --bs-pagination-active-bg: var(--ufal-main-color);
        --bs-pagination-active-border-color: var(--ufal-main-color);

        .page-item{
            .page-link{
                text-decoration: none !important;
                &:focus{
                    box-shadow: none !important;
                }
            }
        }
    }

    
    .related-images{
        a.fresco{
            display: inline-block;
            position: relative;
            background-position: center;
            // width: 100%;
            // height: 145px;
            overflow: hidden;
            &:hover{
                .hiddenLayer{
                    height: 100%;
                }
                .more{
                    font-size: 30px;
                }
            }
        }
        .hiddenLayer{
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            display: block;
            width: 100%;
            height: 0;
            transition: height .2s ease;
        }
        .shadow{
            position: absolute;
            opacity: 0.6;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color:#333;
        }
        .more{
            text-align: center;
            vertical-align: middle;
            text-transform: uppercase;
            color: #fff;
            font-family: "ProximaNovaBold";
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -13.96px;
            margin-top: -16px;
            transition: all .2s ease;
            font-size: 0;
        }
    }

    .related-files{
        a{
            background-color: #f1f1f1;
            padding: 5px 10px;
            border-radius: 5px;
            text-decoration: none;
            margin-bottom:10px;
            &:hover{
                background-color: #ddd;
            }
        }
    }

    //button e description views
    .buttonView{
        margin-bottom: 15px;
    }

    .buttonItem{
        display: block;
        background-color: #aebdcb;
        padding: 42px 5px;
        text-align: center;
        position: relative;
        height: 100%;
        text-decoration: none;
        overflow: hidden;
        color: #fff !important;
        border-radius: 5px !important;
        transition: all .2s ease;

        &:hover{
            &:after{
                left: 5px;
            }
        }
    }

    .buttonItem:after{
        background-image: url('../imgs/brasao-branco.png');
        background-repeat: no-repeat;
        content: "";
        opacity: 0.2;
        top: 0;
        left: -13px;
        bottom: 0;
        right: 0;
        position: absolute;
        transition: all .2s ease;
    }

    .buttonItem span{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        font-family: ProximaNovaBold;
    }

    .descriptionView{
        margin-bottom: 15px;

        a{text-decoration: none !important;}

        .descriptionItem{
            flex: 1;
            height: 100%;
            border: 1px solid #bfc6cf;
            border-radius: 5px;
            position: relative;
        }

        .title{
            padding: 15px 25px;
            background-color: #aebdcb;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            position: relative;
            width: calc(100% + 2px);
            top: -1px;
            left: -1px;
            transform: scale(1, 1);

            a{color: #fff !important;}
        }

        .description{
            padding: 15px 25px;
        }

        ul.content-itens{
            margin: 0 0 0 15px;
            padding: 0;
        }
    }

    .lista-detalhe{
        .item{
            margin-bottom: 13px;
            display: flex;
        }

        a:hover{
            color: var(--ufal-main-color) !important;
        }

        .imagem,.detalhe{
            display: inline-block;
            vertical-align: top;
            top:0;
        }

        .imagem{
            width: 150px;
            position: relative;
            background-color: #e9e9e9;
            text-align: center;
            font-size: 50px;
            overflow: hidden;
            img{
                width:150px;
                height: 100%;
                object-fit: cover;
            }

            i{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .imagem + .detalhe{
            width: calc(100% - 150px);
            min-height: 150px;
            margin-left: -4px;
        }

        .detalhe{
            width: 100%;
            background-color: #f2f2f2;

            .dados{
                padding: 20px;
            }

            .data{
                color: #555555;
                text-transform: uppercase;
                font-family: "ProximaNovaBold";
                margin-bottom: 10px;
                font-size: 12px;

                .atualizado{
                    text-transform: uppercase;
                    background: #1ea714;
                    border-radius: 5px;
                    display: inline-block;
                    color: #fff;
                    padding: 2px 15px;
                    margin-left: 10px;
                }
            }

            .titulo{
                font-size: 20px;
                text-decoration: none !important;
                font-family: "ProximaNovaBold";
                margin-bottom: 15px;
                display: block;
                color:#333;
            }

            .descricao{
                color: #555555;
                font-size: 12px;
                margin-bottom: 0 !important;
            }
        }

        &.pequena{
            .imagem{
                width: 80px;
                height: 80px;
                min-height: 80px;
                font-size: 30px;
            }
            .imagem + .detalhe{
                width: calc(100% - 80px);
            }
            .detalhe{
                margin-left: 0;
                min-height: 80px;
                .dados{
                    padding: 10px;
                }
                .data{
                    font-size: 11px;
                    margin-bottom: 5px;
                }
                .titulo{
                    font-size: 17px;
                    margin-bottom: 10px;
                }
                .descricao{
                    display: none;
                }
            }
        }
    }

    .arrow-list{
        padding: 30px 29px 18px;
        list-style: none;
        margin: 0;
        li{
            background-color: #f2f2f2;
            border-radius: 5px;
            margin-bottom: 12px;
            padding: 23px 29px;
            position: relative;
            overflow: hidden;
            
            &:hover .arrow{
                transform: translateX(-86px);
            }

            a{
                font-family: "ProximaNovaBold";
                font-size: 18px;
                margin-bottom: 7px;
                display: inline-block;
                color: #333;
            }
            .data{
                font-family: "ProximaNovaBold";
                font-size: 12px;
                color: var(--ufal-main-color);
                margin-bottom: 10px;
            }
            .decription{
                font-family: "ProximaNova";
                font-size: 12px;
                color: #808080;
                margin-bottom: 0;
            }
            .arrow{
                position: absolute;
                top: 0;
                right: -86px;
                background: var(--ufal-main-color);
                padding: 0 17px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                height: 100%;
                font-size: 25px;
                vertical-align: middle;
                transition: all 0.5s;
                color: #fff !important;
                line-height: 1;
                span{
                    position: relative;
                    top: calc(50% - 15px);
                }
            }
        }
    }

    .rss_link{
        text-decoration: none !important;
        float: right;
        margin-top: -25px;
        i{color:var(--ufal-main-color)}
    }

    // busca

    &.template-search{
        #portal-breadcrumbs{display: none}
        #content{
            border-top: 5px solid var(--ufal-main-color);
            padding-top: 20px;
        }
    }
    form#searchform{
        position: relative;

        .input-group{display: none}
        .actionMenu{
            .actionMenuHeader{
                float: right;
                #search-filter-toggle{outline: none}
            }
            .actionMenuContent{
                display: none;
                position: absolute;
                right: 0;
                top: 28px;
                z-index: 20;
                background: #fff;
                border: 1px solid #cbcbcb;
                padding: 10px 20px;
            }

        }
        .highlightedSearchTerm{
            color: #ee1c24;
            &:before{content: "'"}
            &:after{content: "'"}
        }

        #rss-subscription{float: right;}

        #search-results-bar{
            margin-bottom: 37px;
        }
        .autotabs{
            border: 1px solid #cbcbcb;
            border-radius: 5px;

            .autotoc-nav{
                padding: 5px 22px 0;
                background-color: #f2f1f1;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                margin-bottom: 20px;

                .autotab-heading{
                    margin-right: 10px;
                }
                a{
                    padding: 15px;
                    display: inline-block;
                    background-color: #fcfcfd;
                    text-decoration: none;
                }
                a.active{background-color: #fff;}
            }

            #search-results{
                ol{
                    list-style: none;
                    padding: 0 15px;

                    li{
                        border-bottom: 2px solid #e9e9e9;
                        padding-bottom: 30px;
                        margin-top: 30px;

                        .result-title{
                            font-size: 20px;
                            display: block;
                            a{
                                text-decoration: none;
                                color:#000;
                                &:hover{
                                    color: #ee1c24;
                                }
                            }
                            img{display: none}
                        }
                        .croppedDescription{
                            color: #555;
                        }

                        .documentLocation{display: block}

                    }
                }
                p{
                    padding: 0 15px;
                }
            }
        }
    }

    // #agenda{
    //     table{
    //         border-radius: 0;
    //     }

    //     .fc-toolbar h2{
    //         padding-top: 9px;
    //     }

    //     .fc-head-container{
    //         border-radius: 0;
    //         border: none;
    //         border-bottom: 1px solid #cbcbcb;
    //     }

    //     .fc-head table{
    //         margin: 0;
    //     }

    //     .fc-day-header{
    //         border: none;
    //         padding: 15px;
    //         font-size: 13px;
    //         border-radius: 0;
    //     }

    //     .fc-day{
    //         border-right: 1px solid #cbcbcb;
    //     }

    //     tr > .fc-day:last-child{
    //         border-right: none;
    //     }

    //     .fc-day-number{
    //         float: left;
    //     }

    //     .fc-event, .fc-event-dot{
    //         background-color: #ff2b34;
    //         border-color: #ff2b34;
    //     }

    //     .fc-button{
    //         background-color: #707686;
    //         background-image: none;
    //         border: none;
    //         padding: 5px 10px;
    //         color:#fff;
    //         outline: none;
    //     }
    //     .fc-button:hover{
    //         background-color: #8e94a5;
    //     }

    //     .fc-prev-button{
    //         border-top-left-radius: 20px;
    //         border-bottom-left-radius: 20px;
    //     }

    //     .fc-next-button{
    //         border-top-right-radius: 20px;
    //         border-bottom-right-radius: 20px;
    //     }
    // }

    //gallery view
    .gallery{
        width: 100%;

        .cycle-slide {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            width: 100%;

        }

        .cycle-slideshow {
            max-width: 1000px;
            position: relative;
            margin: auto;
            position: relative;
            z-index:1;
        }

        .mySlides, .imgText {
            display: none;
        }

        //título da imagem
        .cycle-overlay {
            box-sizing: border-box;
            width: 100%;
            position: absolute;
            background-color: rgba(0,0,0,0.7);
            z-index: 150;
            margin-left: -3%;
            padding-left: 3%;
            padding-bottom: 7%;
            bottom: 0%;
            left: 3%;
        }

        #overlayTitle {
            font-weight: bold;
            color: white;
            position: absolute;
            top: 35%;
            left: 3%;
        }

        //descrição e número de slides
        #custom-caption {
          margin-top: -5%;
          margin-bottom: -1%;
          height: 35px;
        }

        .slideCounter {
            float: right;
        }

        //pager
        #adv-custom-pager {
            overflow-x: scroll;
            white-space: nowrap;
        }

        .pagerImg {
            margin-right: 1.5%;
        }

        //botões de próximo e anterior
        .prev, .next {
            cursor: pointer;
            position: absolute;
            z-index: 150;
            top: 50%;
            width: auto;
            margin-top: -20px;
            margin-left: 20px;
            margin-right: 20px;
            padding: 10px;
            color: white;
            font-weight: bold;
            font-size: 18px;
            transition: 0.6s ease;
            border-radius: 50%;
            background-color: rgba(0,0,0,0.4);
        }

        .next {
            right: 0;
        }

        .prev:hover, .next:hover {
            background-color: rgba(0,0,0,.7);
        }
        #adv-custom-pager{
            .pagerImg{
                filter: brightness(25%);
                -webkit-filter:brightness(25%);
                -moz-filter:brightness(25%);
            }
            .cycle-pager-active img{
                filter: brightness(100%);
                -webkit-filter:brightness(100%);
                -moz-filter:brightness(100%);
            }
        }
    }

    // Preview view

    #fileview{

        &.fullscreen{
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 999;

            #canvas-container{
                height: 100% !important;
            }

            .foot{
                position: fixed;
                bottom: 0;
                width: 100%;
            }
        }

        .head{
            background-color: #292929;
            color: #fff;
            padding: 16px 28px;


            .name{
                float: left;
                font-size: 13px;
                .file-icon{
                    float: left;
                    margin-right: 10px;
                }
                .details{
                    font-size: 12px;
                    padding-top: 6px;
                }
            }

            .download{
                float: right;
            }

            &:after{
                content: "";
                clear: both;
                display: block;
            }
        }



        #canvas-container{
            background-color: #c4c9cd;
            text-align: center;
            overflow: auto;
            height: 700px;
            position: relative;

            i.fa-rotate{
                position: absolute;
                font-size: 70px;
                top:50%;
                left:50%;
                margin: -40px;
                z-index: 2;
            }

            canvas{
                position: relative;
                z-index: 3;
            }
            
            // &:before{
            //     font-family: "Font Awesome 6 Free";
            //     content: "\f2f1";
            //     -webkit-animation-name: fa-spin;
            //     animation-name: fa-spin;
            //     -webkit-animation-delay: var(--fa-animation-delay, 0s);
            //     animation-delay: var(--fa-animation-delay, 0s);
            //     -webkit-animation-direction: var(--fa-animation-direction, normal);
            //     animation-direction: var(--fa-animation-direction, normal);
            //     -webkit-animation-duration: var(--fa-animation-duration, 2s);
            //     animation-duration: var(--fa-animation-duration, 2s);
            //     -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            //     animation-iteration-count: var(--fa-animation-iteration-count, infinite);
            //     -webkit-animation-timing-function: var(--fa-animation-timing, linear);
            //     animation-timing-function: var(--fa-animation-timing, linear);
            // }

            button{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                background-color: transparent;
                padding: 0 10px;
                font-size: 50px;
                outline: none;
                color:#f4f4f4;

                &:hover{
                    color: #9a9fa2;
                }
                &#prev{left: 0}
                &#next{right: 0;}
            }
        }

        .foot{
            background-color: #292929;
            padding: 14px 30px;
            color: #fff;
            position: relative;
            line-height: 18px;

            .zoom{
                float: left;
            }

            #scale{
                float: left;
                padding: 10px 0;
                margin-left: 14px;
            }

            #toggle-screen{
                float: right;
                border: none;
            }

            #form-page{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                #page_num{
                    width: 35px;
                    background-color: #373737;
                    border: none;
                    text-align: right;
                    color: #fff;
                    padding: 6px 10px;
                }
            }

            button{
                color: #fff;
                font-size: 18px;
                background-color: transparent;
                border: 1px solid #fff;
                outline: none;

                &#zoom_out{
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    padding: 6px 9px 6px 14px;
                }

                &#zoom_in{
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    padding: 6px 14px 6px 9px;
                    margin-left: -4px;
                }

                &:hover{
                    background-color: #373737;
                }
            }

            &:after{
                content:"";
                clear: both;
                display: block;
            }
        }
    }

    ///////

    .social{
        float: right;
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0;

        li{
            display: inline-block;
            margin-left: 10px;
        }

        a{
            display: inline-block;
            text-align: center;
            background: #fff;
            width: 23px;
            height: 23px;
            border-radius: 11px;
            color: #116798;
            line-height: 1.5;
            transition: all .2s ease-in-out
        }
    }

    .share {
        float: right;
        margin-top: -70px;
        
        a{
            display: block;
            padding: 10px 15px;
            color: #fff;
            float: left;
            // text-align: center;
            // width:100%;
            // transition: all .2s ease;
            &.facebook{background-color: #3a5799}
            &.twitter{background-color: #00abf0}
            &.x-twitter{background-color: #333;}
            &.linkedin{background-color: #127bb5}
            &.whatsapp{background-color: #43c654}
        }
    }



    /* footer */
    #footer{
        background-color: #4a4e58;
        padding:70px 0;
        font-size: 14px;

        .identificacao{
            margin-bottom: 29px;
            img{vertical-align: top}
            h2 {
                font-size: 19px;
                margin-bottom: 29px;
                font-family: 'Gill Sans MT';
                text-transform: uppercase;
                line-height: 29px;
                margin-top: 29px;
                display: inline-block;
            }
        }

        .social{
            float: none;
            margin-bottom: 21px;

            li{
                margin-left: 0;
                margin-right: 10px;
            }

            a{
                color: #4a4e58;
                padding-top: 2px;
                &:hover{
                    background-color: #3f4350;
                    color: #fff;
                }
            }
        }

        .creditos{
            font-family: "ProximaNova";
            font-size: 10px;
            color: #fff;
        }

        .links-uteis{
            list-style: none;
            column-count: 2;
            padding: 0;

            li{
                -webkit-column-break-inside: avoid;
            }
        }

        .links-uteis a{
            background-color: #5b606c;
            border-radius: 20px;
            padding: 10px 26px;
            font-family: "ProximaNova";
            font-size: 12px;
            color: #fff;
            margin-bottom: 7px;
            display: block;
            transition: all .2s ease-in-out;
        }

        .links-uteis a:hover{
            background-color: #3f4350;
            text-decoration: none;
        }
    }

    // Telefones

    .telefone-view{
        h2{
            margin: 20px 0;
        }
        .block{
            display: flex;
            
            &.head{
                .column{
                    border-top:1px solid #c4c9cd;
                    font-weight: bold;
                }
            }

            .column{
                flex:1;
                padding: 15px 20px;
                border-bottom:1px solid #c4c9cd;
                border-left:1px solid #c4c9cd;

                &:last-child{
                    border-right:1px solid #c4c9cd;
                }

                &.fone{
                    text-align: center
                }
            }
        }
    }
}
