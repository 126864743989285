// fix para simpleLigbox
body.frontend{
    .sl-overlay{
        background-color: #000;
        opacity: 0.7;
    }
    .sl-wrapper{
        button{
            padding: 0;
            background-color: inherit;
            color: #fff;
            border: inherit;
        }

        .sl-caption {
            display: inline-block;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: normal;
            font-size: 1.4em;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -54px;
            padding: 0.71429em 0;
            color: #fff;
            color: rgba(255,255,255,0.7);
            text-align: center;
            background: none;
        }
    }


    // fix para datepicker
    .pattern-pickadate-wrapper .btn {
        vertical-align: top;
        margin: 0.2em;
    }
    .pattern-pickadate-wrapper .btn {
        display: inline-block;
        margin-bottom: 0;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .pattern-pickadate-wrapper .btn-info {
        color: #ffffff;
        background-color: #5bc0de;
        border-color: #46b8da;
    }
    .pattern-pickadate-wrapper .btn-danger {
        color: #ffffff;
        background-color: #d9534f;
        border-color: #d43f3a;
    }

    .picker__header {
        text-align: center;
        position: relative;
        margin-top: .75em;
    }

    .pattern-pickadate-wrapper .picker__select--month, .pattern-pickadate-wrapper .picker__select--year {
        height: 3em;
    }

    .picker__select--year {
        width: 22.5%;
    }

    .picker__select--month {
        width: 35%;
    }
    .picker__select--month, .picker__select--year {
        border: 1px solid #b7b7b7;
        height: 2em;
        padding: .5em;
        margin-left: .25em;
        margin-right: .25em;
        display: inline-block;
    }

    // fix para PFG
    .pfg-form{
        width: 100%;
        float: none;

        .field, .action, .PFGFieldsetWidget{
            width: 100%;
        }
    }

    .easyformForm .field span.option{
        display:block
    }

    .event-summary {
        margin-bottom: $spacer * 2;
        
        .meta-icon {
            margin-right: $spacer * .5;
            margin-top: -0.2em;
        }
        
        .meta-label {
            display: none;
        }
    }
}

p.text-justify {
    text-align: justify;
}

#edit-zone{
    .nav-link{
        text-decoration: none;

        &:hover{
            background-color: var(--bs-primary);
        }
    }
}