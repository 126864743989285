@keyframes slider {
    0% {bottom: -100%;}
    100%{bottom: 0;}
}

#carroussel{

    .swiper, .swiper-wrapper{
        height: 100%;
    }

    .itens{
        width: 100%;
        height: 376px;
        overflow: hidden;
        position: relative;
    }
    .item{
        width: 100%;
        height: 100%;
        background-position: center;
        position: relative;
        img{
            object-fit: cover;
            height: 100%;
        }
        .imagem {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: transform .2s ease;
            background-position: center;
        }
        .color{
            width: 100%;
            height: 100%;
            position: relative;

            &.Verde{background-color: #27ae60}
            &.Azul{background-color: #3854d3}
            &.Amarelo{background-color: #f1c40f}
            &.Laranja{background-color: #f39c12}
            &.Cinza{background-color: #bdc3c7}
            &.Vermelho{background-color: #e74c3c}

            &:after{
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
            }
            &.Bloco::after{background-image: url(../imgs/patterns/block.png);}
            &.Diamante::after{background-image: url(../imgs/patterns/diamond.png);}
            &.Horizontal::after{background-image: url(../imgs/patterns/horizontal.png);}
            &.Linha::after{background-image: url(../imgs/patterns/lined.png);}
            &.Linhas::after{background-image: url(../imgs/patterns/lines.png);}
            &.Quadrado::after{background-image: url(../imgs/patterns/square.png);}
        }
        .controles{
            top: 5px;
            right: 5px;
        }
        .dados{
            position: absolute;
            bottom: -100%;
            padding-bottom: 30px;
            padding-left:10px;
            width: 100%;
        }
        .titulo{
            color: var(--ufal-carousel-text);
            background-color: var(--ufal-carousel-bg);
            display: inline-block;
            font-size: 30px;
            font-family: "ProximaNovaBold";
            padding: 10px;
        }
        .descricao{
            color: var(--ufal-carousel-secondary-text);
            background-color: var(--ufal-carousel-secondary-bg);
            display: table;
            font-size: 16px;
            font-family: "ProximaNova";
            padding: 10px;
        }
        a:hover{
            color: #ed1c24;
        }
        &:hover .imagem{
            transform: scale(1.05);
        }        
    }

    .swiper.swiper-finished .dados,
    .swiper:not(.swiper-initialized) .dados{
        animation: slider 250ms ease-in-out;
        bottom: 22px;
    }

    .swiper-button-next:after, .swiper-button-prev:after{
        color: var(--ufal-carousel-bg);
    }

    .swiper-pagination-bullet{
        background: var(--ufal-carousel-secondary-bg);
    }
    .swiper-pagination-bullet-active{
        background: var(--ufal-carousel-bg);
    }
    // .acoes {

    //     a{
    //         position: absolute;
    //         font-size: 31px;
    //         color: #fff;
    //         padding: 5px 15px;
    //         border-radius: 20px;
    //         overflow: hidden;
    //         z-index: 101;
    //         top: calc(50% - 21.5px);
    //         line-height: 1;
    //         &:hover .shadow{
    //             background-color: #333;
    //         }
    //     }

    //     i, .fa{
    //         position: relative;
    //         z-index: 1;
    //     }

    //     .shadow{
    //         width: 100px;
    //         height: 100%;
    //         position: absolute;
    //         background-color: #000;
    //         opacity: .5;
    //         top: 0;
    //         left: 0;
    //         z-index: 0;
    //     }

    // }

    .empty{
        height: 376px;
        background-color: #eee;
    }
}


#carroussel .acoes .prev{left: 10px;}
#carroussel .acoes .prev i{margin-right: 2px;}
#carroussel .acoes .next{right: 10px;}
#carroussel .acoes .next i{margin-left: 2px;}

#carroussel .pontos{
    text-align: center;
    padding: 10px 0;
}
#carroussel .pontos button{
    color:#fff;
    font-size: 33px;
    display: inline-block;
    padding: 0 5px;
    cursor: pointer;
    border-radius: 50%;
}
#carroussel .pontos button.active{
    background-color: #eb1d21;
}

#carroussel .first-tile{
    color: #fff;
    font-size: 80px;
    left: calc(50% - 34.29px);
    top: calc(50% - 41px);
    position: absolute;
    z-index: 1;
}

#carroussel-sort ul{
    list-style: none;
    margin: 10px 0;
    padding: 0;
}

#carroussel-sort li{
    padding: 5px 10px;
    margin-bottom: 2px;
    cursor: ns-resize;
    border: 1px solid #ccc;
    background: #ddd;
}

#carroussel-sort li.item-dragging{
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
    background: #F5D76E;
}

// controles de tiles
.controles{
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 3;
    background: #efefef;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.controles a{
    width: 30px;
    text-align: center;
    color: #333;
    padding: 3px 6px;
    display: inline-block;
    border-radius: 10px;
}

.controles a:hover{
    background-color: #d7d7d7;
}


ul.noticia-list{
    padding: 0 0 0 20px;
    border-left-style: solid;
    border-left-width: 4px;
    list-style: none;

    span.data{
        font-family: "ProximaNovaBold";
        font-size: 12px;
    }

    a.titulo{
        display: block;
        color:#333;
        font-family: 'ProximaNova' !important;
        padding: 0 0 8px!important;
        text-decoration: none !important;
    }
}


@media (max-width: 992px) {
    #carroussel{
        .dados{
            padding: 10px !important;
        }
        .titulo{
            margin-bottom: 0 !important;
            font-size: 25px !important;
            display: block !important;
        }
        .descricao{
            font-size: 15px !important;
            display: block !important;
        }
        .next, .prev{
            display:none
        }
    }
}

@mixin overlay {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    display: block;
    width: 100%;
    height: 0;
    transition: height 0.2s ease;
}

@mixin shadow {
    position: absolute;
    opacity: 0.6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: var(--ufal-shadow-color);
}

@mixin more {
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
    color: #fff;
    font-family: "ProximaNovaBold";
    font-size: 14px;
    z-index: 2;
    position: absolute;
    top: calc(50% - 7px);
    left: calc(50% - 38.5px);
    visibility: hidden;
    transition: all 0.2s ease;
}

@mixin normal-width{
    margin-left: -37px;
    margin-right: -37px;
    padding-left: 37px;
    padding-right: 37px;

}

@mixin full-width{
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    & > .container{
        padding-left: 50px;
        padding-right: 50px;
    }
}

.tiles{
    position: relative;
    
    .tile-add{
        position: relative;
        display: inline-block;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color: #fff;
        cursor: pointer;
        padding:10px 10px 0 10px;
        margin-bottom: 20px;
        
        .options{
            transition: max-height .01s ease-in;
            position: absolute;
            overflow: hidden;
            background-color: var(--ufal-main-color);
            max-height:0;
            width: 100%;
            left: 0;
            z-index: 101;
            
            padding-top: 10px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .option{
                padding: 5px 10px;
                &:hover{
                    display: block;
                    background-color: #fff;
                    a{color: var(--ufal-main-color) !important;}
                }
            }
            a{
                color: #fff !important;
                text-decoration: none !important;
            }
        }

        &:hover .options{
            max-height:350px;
        }

    }

    &.manager .tile-wrapper{
        border: 1px dashed var(--ufal-main-color);
        margin-bottom: 5px;
    }

    .tile-wrapper{
        display: flex;
        flex-direction: column;

        
        .tile-controls{
            flex: 1;
            opacity: .5;
        }
        
        &:hover .tile-controls{
            opacity: 1;
        }
        
    }
    
    .tile-name{
        flex: 1;
        padding:3px 6px;

        select{
            width: inherit;
            height: 25px;
            padding: 0 10px;
        }
    }
    
    .tile-controls{
        background: #efefef;
        border: 1px solid #ccc;
        display: flex;
        z-index: 100;
        width: 100%;
        
        a{
            width: 30px;
            text-align: center;
            color: #333;
            padding: 3px 6px;
            display: inline-block;
            border-radius: 10px;
            &:hover{
                background-color: #d7d7d7;
            }
        }
    }

    .new-line{
        display: block;
        background-color: #efefef;
        text-align: center;
        text-decoration:none !important;
        border-radius: 5px;
        border:1px solid #ccc;
    }

    // Hide old controls inside panel page
    // .controles{display: none;}

    .tile-title{
        font-family: "ProximaNova";
        margin-bottom: 20px;
        border-left: 4px solid var(--ufal-tile-title);
        padding-left: 10px;
    }

    .tile-header{
        h1,h2,h3,h4,h5{
            font-family: "ProximaNova";
        }
        &.border{
            h1,h2,h3,h4,h5{
                border-left: 4px solid var(--ufal-tile-title);
                padding-left: 10px;
            }
        }
    }

    .tile-hero{
        background-color: var(--ufal-hero-bg);
        margin-bottom: 15px;
        padding-top: 7.5rem!important;
        padding-bottom: 4rem!important;
        color: #fff;
        
        &.normal-width{@include normal-width}

        &.full-width{@include full-width}

        .heading{
            margin-bottom: 3rem!important;
            padding-right: 1rem!important;
        }

        .lead{
            color: rgba(255,255,255,.7)!important;
            margin-bottom:16px;
        }

        .btn-primary{
            background-image: linear-gradient(90deg,black, var(--ufal-hero-primary),white);
            background-repeat: no-repeat;
            background-size: 10000% 100%;
            background-position-x: var(--ufal-darken);
            color: #fff !important;
            text-decoration: none !important;
            font-size: 1rem;
            border:none;
            border-radius: 0.3125rem;
            &:hover{
                background-position-x: var(--ufal-darkest);
            }
        }

        .btn-secondary{
            color: #f9fafc;
            background-color: transparent;
            border: none;
            border-radius: 0.3125rem;
            font-size: 1rem;
            transition: none;
            &:hover{
                background-image: linear-gradient(90deg,black, var(--ufal-hero-secondary),white);
                background-repeat: no-repeat;
                background-size: 10000% 100%;
                background-position-x: var(--ufal-lighten);
            }
        }
    }

    .tile-hlist{
        
        &.normal-width{@include normal-width}
        &.full-width{@include full-width}

        &.bg {
            background-color: var(--ufal-hlist-bg);
            // margin-left: -37px;
            // margin-right: -37px;
            padding: 37px;
            .tile-title{
                color: #fff;
            }
        }
        
        .items{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
        }
        .hlist-item{
            position: relative;
            background-color: var(--ufal-hlist-color);
            padding: 25px;
            height: 100%;
            filter: drop-shadow(3px 3px 2px #ccc);
            border-radius: 5px;
            // margin: 0 auto;
            
            @media (max-width: 767px) {flex-basis: 100%;}
            @media (min-width: 768px) {flex-basis: 100%;}
            @media (min-width: 992px) {flex-basis: 49%;}
            @media (min-width: 1200px) {flex-basis: 32%;}

            &:hover {
                .overlay {
                    height: 100%;
                }
                .more{
                    visibility: visible;
                }
                & a > span{
                    filter: blur(8px);
                }
            }

            & > a{
                text-align: center;
                color: #fff !important;
                text-decoration: none !important;
                display: grid;
                align-items: center;
                font-size: 1.5rem;
                transition: all .2s linear;
                min-height: 200px;
                font-family: "ProximaNova";
            }

            .overlay{@include overlay}
            .shadow{
                @include shadow;
                background-image: linear-gradient(90deg,black, var(--ufal-hlist-color),white);
                background-repeat: no-repeat;
                background-size: 10000% 100%;
                background-position-x: var(--ufal-lighten);
            }
            .more{@include more;}
        }
    }

    .tile-buttons{

        &.bg {
            background-color: var(--ufal-buttons-bg);
            margin-left: -37px;
            margin-right: -37px;
            padding: 37px;
            .tile-title{
                color: #fff;
            }

            form{
                input{
                    border:none;
                }

                button{
                    border: none;
                    background-color: var(--ufal-buttons-bg);
                    &:hover{
                        background-color: var(--ufal-buttons-color);
                        color: #fff;
                    }
                }
            }
        }

        &.normal-width{@include normal-width}

        &.full-width{@include full-width}


        // .tile-title{
        //     text-align: center;
        //     border:none;
        // }

        form{
            display: flex;
            place-content: center;
            margin: 15px 0;
            .input-group{
                width: inherit;
                border: 1px solid #fff;
            }
            .input-group-append{border-radius:0}
            input{
                padding: 5px 10px;
                border-radius:0;
            }
            button{
                border-radius: 0;
                padding: 2px 10px;
                font-size: 20px;
                line-height: inherit;
                transition: none;
            }
        }

        .items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .tile-button-item {
                @media (max-width: 767px) {flex-basis: 100%;}
                @media (min-width: 768px) {flex-basis: 44%;}
                @media (min-width: 992px) {flex-basis: 29%;}
                @media (min-width: 1200px) {flex-basis: 21%;}
                
                flex: 0 0;
                margin: 1rem;
                position: relative;
                place-items: center;
                transition: outline .2s linear;
                background-color: var(--ufal-buttons-color, var(--ufal-main-color));
                outline: 1px solid var(--ufal-buttons-color, var(--ufal-main-color));
                
                &:hover{
                    background-image: linear-gradient(90deg,black, var(--ufal-buttons-color),white);
                    background-repeat: no-repeat;
                    background-size: 10000% 100%;
                    background-position-x: var(--ufal-darken);
                    outline-color: #fff;
                    span {
                        margin-bottom:30px;
                    }
                }
                
                & > a{
                    font-family: "ProximaNova";
                    color: #fff !important;
                    text-decoration: none !important;
                    display: grid;
                    text-align: center;
                    min-height: 200px;
                    transition: all .2s ease-in-out;
                }

                span{
                    display: block;
                    font-size: 3rem;
                    margin-bottom: 15px;
                    transition: all .2s linear;
                    align-self: self-end;
                }
            }
        }
    }

    .tile-schedule{

        &.bg{
            padding-top: 30px;
            padding-bottom: 30px;
        }
        background-color: var(--ufal-schedule-background);
        &.normal-width{@include normal-width}

        &.full-width{@include full-width}
        
        .months{
            padding: 0 12px;
            text-align: center;
            margin-top: 30px;
        }

        .month{
            color: var(--ufal-main-color);
            font-family: "ProximaNovaBold";
            text-transform: uppercase;
        }

        .year{
            color: #808080;
            font-family: "ProximaNovaBold";
        }

        .previous_month{
            float:left;
        }

        .next_month{
            float: right;
        }

        .calendar{
            width: 100%;
            font-family: "ProximaNovaBold";
            font-size: 14px;
            border: none;

            thead{
                height: 3.813em;
                padding: 0.7em;
                z-index: 2;
                color: var(--ufal-main-color);
            }

            tr{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                box-sizing: border-box;
            }

            th, td{
                display: inline-block;
                padding: 0.7em 0;
                text-align: center;
                box-sizing: border-box;
                -ms-flex-preferred-size: 14.28571%;
                flex-basis: 14.28571%;
                background-image: radial-gradient(var(--ufal-main-color) 50%, rgba(0, 0, 0, 0) 53%);
                background-size: 0 0;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                border: none;
            }
            .event{
                color: #FFFFFF;
                background-size: 3.5em 3.5em;
            }
        }

        .event-list{
            background-color: #e9e9e9;
            border-radius: 5px;
            height: 414px;

            overflow: auto;
            scrollbar-color: var(--ufal-main-color) transparent;
            scrollbar-width: thin;
        }
    }
}

.tile-destaque{
    position: relative;
    .image_link{
        position: relative;
        display: block;
        &:hover {
            .overlay {
                height: 100%;
            }
            .more{
                visibility: visible;
            }
        }
    }

    .overlay{@include overlay;}
    .shadow{@include shadow;}
    .more{@include more;}

    .pretty_date {
        background-color: #22a0da;
        display: inline-block;
        padding: 5px 15px;
        font-family: "ProximaNovaBold";
        font-size: 10px;
        color: #fff;
        margin-top: 10px;
    }

    .titulo {
        font-family: "ProximaNovaBold";
        font-size: 18px;
        padding: 15px 0;
        display: block;
        line-height: 1.1;
        text-decoration: none !important;
        color: var(--ufal-main-color) !important;
        &:hover{
            text-decoration: underline !important;
            text-decoration-color: #fbd6d7 !important;
        }
    }
}

.tile.news{
    h2 {
        margin: 5px 0;
        font-size: 24px;
    }
    ul.news-list {
        padding: 0 0 0 20px;
        border-left-style: solid;
        border-left-width: 4px;
        list-style: none;
        border-color: var(--ufal-main-color);

        span.data {
            font-family: ProximaNovaBold;
            font-size: 12px;
            color: var(--ufal-main-color);
        }

        a.titulo {
            display: block;
            color: #333;
            font-family: ProximaNova!important;
            padding: 0 0 8px!important;
            text-decoration: none!important;
        }
    }
}