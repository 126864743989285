$ufal: #c6151c;
$ufal-alt: #92192a;

:root{
    --ufal-main-color: #{$ufal};
    --ufal-alt-color: #{$ufal-alt};
    --ufal-carousel-bg: #074875;
    --ufal-carousel-text: #fff;
    --ufal-carousel-secondary-bg: #126a9c;
    --ufal-carousel-secondary-text: #fff;
    --ufal-hero-bg: #{$ufal};
    --ufal-tile-title: #{$ufal};
    --ufal-hlist-color: #{$ufal};
    --ufal-hero-primary: #fff;
    --ufal-hero-secondary: #fff;
    --ufal-shadow-color: #22a0da;

    --ufal-darkest: 25%;
    --ufal-darken: 45%;
    --ufal-lighten: 80%;
}

body.frontend{
    .breadcrumb{
        --bs-breadcrumb-bg: #ffffff;
    }
}