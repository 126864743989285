/* navegacao */
body.frontend{

    .portletNavigationTree{
        margin: 16px 0;
        background-color: transparent;
        border: none;

        .card-body{padding:0}

        .portletHeader a,
        .navTreeTopNode a{
            font-family: "ProximaNovaBold";
            background-color: transparent !important;
            font-size: 18px;
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
        }

        a{
            color: #fff !important;
            font-family: "ProximaNova";
            padding: 12px 30px;
            display: block;
            font-size: 13px;
        }

        a:hover{
            text-decoration: none;
            background-color: var(--ufal-alt-color);
        }
    }

    .navTreeTopNode a{
        font-family: "ProximaNovaBold";
        background-color: transparent !important;
        font-size: 18px;
    }

    .navTreeCurrentItem{
        font-family: "ProximaNovaBold" !important;
    }

    .navTreeItem{
        .plone-icon{display: none;}
    }

}
