@font-face {
	font-family: 'Gill Sans MT';
	src: url('../fonts/GillSansMT-Bold.eot');
	src: url('../fonts/GillSansMT-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GillSansMT-Bold.woff') format('woff'),
		url('../fonts/GillSansMT-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('../fonts/ProximaNovaSoft-Regular.eot');
    src: url('../fonts/ProximaNovaSoft-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNovaSoft-Regular.woff2') format('woff2'),
        url('../fonts/ProximaNovaSoft-Regular.woff') format('woff'),
        url('../fonts/ProximaNovaSoft-Regular.ttf') format('truetype'),
        url('../fonts/ProximaNovaSoft-Regular.svg#ProximaNovaSoft-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNovaBold';
    src: url('../fonts/ProximaNovaSoft-Bold.eot');
    src: url('../fonts/ProximaNovaSoft-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ProximaNovaSoft-Bold.woff2') format('woff2'),
        url('../fonts/ProximaNovaSoft-Bold.woff') format('woff'),
        url('../fonts/ProximaNovaSoft-Bold.ttf') format('truetype'),
        url('../fonts/ProximaNovaSoft-Bold.svg#ProximaNovaSoft-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
