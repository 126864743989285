body.frontend{

    .field{
        margin-bottom: 15px;
    }

    label{
        font-family: "ProximaNovaBold";
    }

    .field.error {
        background: #fbe8e4;
        -webkit-box-shadow: 0 0 0 5px #fbe8e4;
        box-shadow: 0 0 0 5px #fbe8e4;

        input, textarea, select{
            border: 1px solid var(--ufal-main-color);
            color: var(--ufal-main-color);
        }

        input:focus, select:focus, textarea:focus{
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(237, 28, 36, 0.6);
        }
    }

    .formHelp{
        color: #666666;
        font-weight: 400;
        display: block;
    }

    .formControls{
        border-top: 1px solid #cccccc;
        background: #fcfcfd;
        padding: 12px;
        box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
    }

    .context{
        color: #fff;
        background-color: #007bb1;
        border-color: #00587e;
    }

    .context:hover, .context:focus, .context:active, .context.active {
        color: #fff;
        background-color: #00587e;
        border-color: #002d41;
    }

    .destructive {
        color: #fff;
        background-color: #bb4f45;
        border-color: #963f37;
    }

    .destructive:hover, .destructive:focus, .destructive:active, .destructive.active{
        color: #fff;
        background-color: #963f37;
        border-color: #692c26;
    }

    label span.required:after{
        color: #c4183c;
        content: "•";
        font-size: 200%;
        line-height: 0;
        position: relative;
        top: 3px;
        margin-left: -4px;
    }

    .btn-more{
        background-color: var(--ufal-main-color);
        color:#fff !important;
        text-decoration: none !important;
        
        &:hover{
            background-color: var(--ufal-alt-color);
        }
    }

    .btn-more-blue{
        background-color: #376bc9;
        color:#fff !important;
        text-decoration: none !important;
        &:hover{
            background-color: #4f7fd7;
        }
    }
    .btn-primary{
        --bs-btn-bg:var(--ufal-main-color);
        --bs-btn-border-color:var(--ufal-main-color);
        --bs-btn-disabled-bg: var(--ufal-main-color);
        --bs-btn-disabled-border-color: var(--ufal-main-color);
        --bs-btn-hover-bg: var(--ufal-alt-color);
        --bs-btn-hover-border-color: var(--ufal-alt-color);
        --bs-btn-active-bg: var(--ufal-alt-color);
        --bs-btn-active-border-color: var(--ufal-alt-color);
    }

    .btn-outline-primary{
        --bs-btn-color: var(--ufal-main-color);
        --bs-btn-border-color: var(--ufal-main-color);
        --bs-btn-hover-bg: var(--ufal-main-color);
        --bs-btn-hover-border-color: var(--ufal-main-color);
        --bs-btn-active-bg: var(--ufal-main-color);
        --bs-btn-active-border-color: var(--ufal-main-color);
        --bs-btn-disabled-color: var(--ufal-main-color);
        --bs-btn-disabled-border-color: var(--ufal-main-color);
    }
}
